<template>
  <v-form ref="form" @submit.prevent="cancelSubscriptionClicked">
    <v-container>
      <v-row>
        <v-col class="text-h5 text-center">
          {{ $trans("cancel_plan_generic_title") }}
        </v-col>
      </v-row>
      <v-row v-if="!showCancelOptions">
        <v-col cols="12">
          <calendesk-information-message color="snow_gray">
            <div class="text-center">
              <div v-if="onTrial">
                {{ $trans("cancel_subscription_title_2") }}
              </div>
              <div>
                {{ $trans("cancel_plan_generic_info") }}
              </div>
            </div>
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="!showCancelOptions"
          cols="12"
          class="d-flex align-center justify-center flex-wrap"
        >
          <v-btn color="blue" outlined class="ma-2" @click="handleMeetUsClick">
            {{ $trans("meet_us") }}
          </v-btn>
          <v-btn
            color="blue"
            outlined
            class="ma-2"
            @click="handleOpenChatClick"
          >
            {{ $trans("wb_chat_with_us") }}
          </v-btn>
          <v-btn
            v-if="!onTrial"
            color="light"
            outlined
            class="ma-2"
            @click="showCancelOptions = true"
          >
            <span class="accent_light--text">{{
              $trans("cancel_plan_2")
            }}</span>
          </v-btn>
        </v-col>
        <template v-else-if="!onTrial && !isFetchingPlans">
          <v-col cols="12" class="text-h5 text-center">
            {{ $trans("cancel_subscription_title") }}
          </v-col>
          <v-col cols="12">
            <v-card
              min-height="100"
              outlined
              class="pointer fill-height calendesk-card position-relative"
              :ripple="false"
              @click="closeDialog"
            >
              <div
                class="d-flex align-center fill-height text-center justify-center"
              >
                <div>
                  <v-card-title class="break-word-normal d-flex justify-center">
                    {{ $trans("no_just_checking_title") }} :)
                  </v-card-title>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              min-height="100"
              outlined
              class="pointer fill-height calendesk-card position-relative"
              :class="{
                'calendesk-card__active': !wantsFullyCancel,
              }"
              :ripple="false"
              @click="wantsFullyCancel = false"
            >
              <div
                class="d-flex align-center fill-height text-center justify-center"
              >
                <div>
                  <v-card-title class="break-word-normal d-flex justify-center">
                    {{ $trans("cancel_subscription_subtitle_2") }}
                    <div
                      v-if="!wantsFullyCancel"
                      class="calendesk-card__checkmark"
                    >
                      <v-icon x-large color="green"> $check-circle </v-icon>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div
                      class="mb-4"
                      v-html="$trans('cancel_subscription_text_3')"
                    />
                    <div class="text-center">
                      <div
                        class="mb-4"
                        v-html="
                          $trans('cancel_subscription_text_4', {
                            price: pausedSubscriptionPrice,
                          })
                        "
                      />
                      <v-btn
                        color="green"
                        outlined
                        large
                        @click="cancelSubscriptionAndKeepDataClicked"
                      >
                        <v-icon left>$pause</v-icon>
                        {{ $trans("cancel_and_keep_data_button_title") }}
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              min-height="100"
              outlined
              class="pointer fill-height calendesk-card position-relative"
              :class="{
                'calendesk-card__active': wantsFullyCancel,
              }"
              :ripple="false"
              @click="wantsFullyCancel = true"
            >
              <div
                class="d-flex align-center fill-height text-center justify-center"
              >
                <div>
                  <v-card-title class="break-word-normal d-flex justify-center">
                    {{ $trans("cancel_plan") }}
                    <div
                      v-if="wantsFullyCancel"
                      class="calendesk-card__checkmark"
                    >
                      <v-icon x-large color="green"> $check-circle </v-icon>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <span v-html="$trans('cancel_plan_1')" />
                    <div v-if="wantsFullyCancel" class="text-center">
                      <div class="my-4">
                        {{ $trans("cancel_subscription_text") }}
                        <span class="font-weight-bold">{{
                          currentPeriodEndsAt
                        }}</span
                        >.<br />
                        {{ $trans("cancel_subscription_text_2") }}<br />
                        <v-alert type="error" class="my-4">
                          {{ $trans("subscription_cancel_data_warning") }}
                        </v-alert>
                      </div>
                      <div>
                        {{ $trans("subscription_cancel_reason_info") }}
                      </div>
                      <v-select
                        v-model="selectedReason"
                        :items="generateReasonOptions"
                        item-text="title"
                        return-object
                        :label="$trans('cancel_reason_dropdown_label')"
                        outlined
                        class="my-4"
                        :rules="[rules.required]"
                        hide-details="auto"
                      >
                        <template #item="{ item }">
                          <div v-if="item.isCategory" class="font-weight-bold">
                            {{ item.title }}
                          </div>
                          <div v-else class="ml-4">{{ item.title }}</div>
                        </template>
                      </v-select>
                      <v-textarea
                        v-model="customerFeedback"
                        :placeholder="
                          $trans('subscription_cancel_reason_placeholder')
                        "
                        height="150"
                        outlined
                        hide-details="auto"
                        :rules="[rules.maxChars(5000)]"
                        class="my-4"
                      />
                    </div>
                    <div v-if="wantsFullyCancel">
                      <v-btn
                        class="ma-1"
                        color="error"
                        outlined
                        large
                        :loading="isCanceling"
                        :disabled="isCanceling"
                        @click="cancelSubscriptionClicked"
                      >
                        <span>{{ $trans("cancel_plan_and_delete_data") }}</span>
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  handleMeetUsClick,
  handleOpenChatClick,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";

export default {
  name: "CancelPlanDialog",
  components: { CalendeskInformationMessage },
  mixins: [planActions],
  data() {
    return {
      isCanceling: false,
      showCancelOptions: false,
      customerFeedback: null,
      selectedReason: null,
      rules: { required, maxChars },
      wantsFullyCancel: false,
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    pausedSubscriptionPlan() {
      return this.plans.find(
        (plan) => plan.slug === this.$helpers.plans.CD_BASE_PAUSED,
      );
    },
    pausedSubscriptionPrice() {
      return this.$options.filters.money(
        this.pausedSubscriptionPeriod.price.amount,
        this.adminConfiguration
          ? this.adminConfiguration.tenant_currency
          : null,
      );
    },
    pausedSubscriptionPeriod() {
      return this.pausedSubscriptionPlan.periods.find(
        (period) => period.period === "month",
      );
    },
    generateReasonOptions() {
      const categories = [
        {
          id: "1",
          title: this.$trans("cancel_subscription_category_1_title"),
          reasons: [
            "cancel_subscription_category_1_reason_1_title",
            "cancel_subscription_category_1_reason_2_title",
            "cancel_subscription_category_1_reason_3_title",
            "cancel_subscription_category_1_reason_4_title",
            "cancel_subscription_category_1_reason_5_title",
            "cancel_subscription_category_1_reason_6_title",
          ],
        },
        {
          id: "2",
          title: this.$trans("cancel_subscription_category_2_title"),
          reasons: [
            "cancel_subscription_category_2_reason_1_title",
            "cancel_subscription_category_2_reason_2_title",
            "cancel_subscription_category_2_reason_3_title",
            "cancel_subscription_category_2_reason_4_title",
            "cancel_subscription_category_2_reason_5_title",
          ],
        },
        {
          id: "3",
          title: this.$trans("cancel_subscription_category_3_title"),
          reasons: [
            "cancel_subscription_category_3_reason_1_title",
            "cancel_subscription_category_3_reason_2_title",
          ],
        },
        {
          id: "4",
          title: this.$trans("cancel_subscription_category_4_title"),
          reasons: [
            "cancel_subscription_category_4_reason_1_title",
            "cancel_subscription_category_4_reason_2_title",
            "cancel_subscription_category_4_reason_3_title",
            "cancel_subscription_category_4_reason_4_title",
            "cancel_subscription_category_4_reason_5_title",
            "cancel_subscription_category_4_reason_6_title",
          ],
        },
        {
          id: "5",
          title: this.$trans("cancel_subscription_category_5_title"),
          reasons: [
            "cancel_subscription_category_5_reason_1_title",
            "cancel_subscription_category_5_reason_2_title",
            "cancel_subscription_category_5_reason_3_title",
          ],
        },
        {
          id: "6",
          title: this.$trans("cancel_subscription_category_6_title"),
          reasons: [
            "cancel_subscription_category_6_reason_1_title",
            "cancel_subscription_category_6_reason_2_title",
          ],
        },
      ];

      const options = [];

      categories.forEach((category) => {
        options.push({
          id: category.id,
          title: category.title,
          isCategory: true,
          disabled: true,
        });

        category.reasons.forEach((reasonKey, index) => {
          options.push({
            id: `${category.id}_${index + 1}`,
            title: this.$trans(reasonKey),
            isCategory: false,
          });
        });
      });

      return options;
    },
  },
  methods: {
    ...mapActions({
      cancelSubscription: "payments/cancelSubscription",
    }),
    cancelSubscriptionClicked() {
      if (!this.$refs.form.validate()) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      pushEvent("cancelPlanLastQuestion");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        warning: this.$trans("cancel_subscription_title"),
        cancelText: this.$trans("no"),
        confirmationText: this.$trans("yes"),
        importantInformation: this.$trans("subscription_cancel_data_warning"),
        confirmAction: () => {
          pushEvent("cancelPlanConfirmation");
          this.cancelPlanAction(false);
        },
      });
    },
    cancelSubscriptionAndKeepDataClicked() {
      pushEvent("cancelPlanAndKeepDataLastQuestion");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        warning: this.$trans("cancel_plan_and_keep_data_confirmation_label"),
        importantInformation: this.$trans(
          "cancel_plan_and_keep_data_confirmation_info",
        ),
        confirmationText: this.$trans("yes"),
        cancelText: this.$trans("no"),
        confirmAction: () => {
          pushEvent("cancelPlanAndKeepDataConfirmation");
          this.cancelPlanAction(true);
        },
      });
    },
    cancelPlanAction(keepData) {
      this.setCommonDialogLoader(true);

      const data = {
        keep_data: keepData,
      };

      if (this.selectedReason) {
        data.customer_feedback = `${this.selectedReason.title}`;

        if (this.customerFeedback) {
          data.customer_feedback += ` / ${this.customerFeedback}`;
        }

        data.reason_id = this.selectedReason.id;
      }

      return this.cancelSubscription(data)
        .then(() => {
          this.fetchRequiredAppComponents()
            .then(() => {
              successNotification("subscription_cancelled_successfully");
              this.closeDialog();
              this.closeConfirmDialog();
            })
            .finally(() => {
              this.isCanceling = false;
              this.setCommonDialogLoader(false);
            });
        })
        .catch((error) => {
          this.setCommonDialogLoader(false);
          errorNotification("subscription_cancel_error", error, false);
        });
    },
    handleMeetUsClick,
    handleOpenChatClick,
  },
};
</script>
